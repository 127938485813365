main {
  @include xy-grid-container;
  background-color: #fffff0;
  
    article.fds-container {
      @include xy-grid;

      header {
        width: 100%;
        padding: rem-calc(25);
        text-align: center;
        h3 {
          font-size: rem-calc(36);
          @include font-bold();
          @include text-transform(uppercase);
        }
      }

      section.fds-row {
        @include xy-cell;
      }
  }//article.fds-container

}// main

form#visits-form {
  width: 100%;
  padding: rem-calc(20);

  label {
    color: $secondary-color;
    font-size: rem-calc(16);
    @include font-bold();
    @include text-transform(uppercase);
  }// label

  input[type="submit"] {
    @include button(expand);
    height: rem-calc(50);
    font-size: rem-calc(18);
    font-weight: 800;
    border: none
  }
}
